import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useSnackbar } from "notistack";

import { updatePassword } from "../api";
import PasswordInput from "./PasswordInput";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100%",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  textField: {
    marginBottom: "10px",
    borderRadius: "5px",
    width: "100%",
  },
  button: {
    width: 150,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");

  const handleUpdatePassword = () => {
    if (newPassword !== retypePassword) {
      enqueueSnackbar("New passwords entered do not match!", { variant: "error" });
      return;
    } else if (currentPassword === newPassword && newPassword === retypePassword) {
      enqueueSnackbar("New password cannot be the same as the current password!", {
        variant: "error",
      });
      return;
    }

    const formData = new FormData();
    formData.append("old_password", currentPassword);
    formData.append("new_password1", newPassword);
    formData.append("new_password2", retypePassword);

    if (Cookies.get("t1")) {
      const decoded = jwt_decode(Cookies.get("t1"));
      updatePassword(decoded.user_id, formData)
        .then((res) => {
          if (res.status === 200) {
            setCurrentPassword("");
            setNewPassword("");
            setRetypePassword("");
            enqueueSnackbar("Password updated successfully!", { variant: "success" });
          }
        })
        .catch((err) => {
          //   console.log(err);
          enqueueSnackbar("Incorrect password!", { variant: "error" });
        });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="h5" style={{ fontWeight: 500 }} gutterBottom>
          Password Management
        </Typography>
        <PasswordInput
          label="Enter Current Password"
          className={classes.textField}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <PasswordInput
          label="Enter New Password"
          className={classes.textField}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <PasswordInput
          label="Re-enter New Password"
          className={classes.textField}
          value={retypePassword}
          onChange={(e) => setRetypePassword(e.target.value)}
        />

        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={currentPassword === "" || newPassword === "" || retypePassword === ""}
            onClick={handleUpdatePassword}
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
