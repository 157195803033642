import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Button,
  Collapse,
} from "@material-ui/core";
import {
  ListAltTwoTone,
  LocalMallTwoTone,
  SettingsTwoTone,
  StorefrontTwoTone,
  LocalShippingTwoTone,
  LocalOfferTwoTone,
  StarsTwoTone,
  ChevronLeft,
  ChevronRight,
  ExitToApp,
  Menu,
  VisibilityTwoTone,
  Add,
} from "@material-ui/icons";

import Logo from "../../../assets/logo.png";
import LogoCollapsed from "../../../assets/logo_no_words.png";
import Auth from "../../../Auth";
import useAuth from "../../../hooks/useAuth";
import { getShopDomain, isAdmin } from "../../../utils";
import useShopContext from "../../../hooks/useShopContext";
import { Select, MenuItem } from "@material-ui/core";
import { useSnackbar } from "notistack";

const drawerWidth = 265;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#FFFFFF",
    position: "relative",
  },
  drawer: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    WebkitOverflowScrolling: "touch",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      width: theme.spacing(0.5),
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[400],
      borderRadius: 2,
    },
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(9) + 1,

    WebkitOverflowScrolling: "touch",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      width: theme.spacing(0.5),
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[400],
      borderRadius: 2,
    },
  },
  drawerContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  logo: {
    alignSelf: "center",
    height: 50,
    margin: theme.spacing(2, 0, 5),
    // imageRendering: "-webkit-optimize-contrast",
    transition: "opacity .2s, visibility .2s, margin-top 0s .2s",
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
  listItem: {
    width: "100%",
    margin: theme.spacing(0.5, 0),
    color: theme.palette.grey[800],
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  nestedLevel1ListItem: {
    paddingLeft: theme.spacing(9),
  },
  nestedLevel2ListItem: {
    paddingLeft: theme.spacing(11),
  },
  bottomListItem: {
    width: "100%",
    margin: theme.spacing(0.5, 0),
    color: theme.palette.grey[800],
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  activeListItem: {
    width: "100%",
    background: theme.palette.grey[200],
    fontWeight: 600,
  },
  activeNestedMenuItem: {
    width: "100%",
    background: theme.palette.grey[200],
  },
  listIcon: {
    margin: theme.spacing(0, 3, 0, 1),
  },
  logout: {
    marginBottom: theme.spacing(1.5),
    color: theme.palette.error.main,
  },
  toggleIcon: {
    margin: theme.spacing(1),
    alignSelf: "flex-end",
  },
  spacer: {
    flex: 1,
  },
  divider: {
    margin: theme.spacing(1),
  },
  profileContainer: {
    padding: theme.spacing(1, 2),
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    whiteSpace: "nowrap",
    color: theme.palette.grey[800],
  },
}));

const SideNav = ({ isMobile, drawerOpen, setDrawerOpen }) => {
  const classes = useStyles({ isMobile: isMobile, drawerOpen: drawerOpen });
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [nestedMenuOpen, setNestedMenuOpen] = useState("");

  const { user, selectVendor, vendor, vendors, createVendor, error } = useAuth();
  const { shopfront } = useShopContext();

  useEffect(() => {
    if (error) {
      if (error.response && error.response.status === 403) {
        enqueueSnackbar(error.response.data || "Something went wrong. Please try again!", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        });
      } else {
        enqueueSnackbar(
          `Something went wrong. Kindly contact us with the error code: ${
            error.response && error.response.status
          }`,
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          },
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const VendorSelector = () => {
    const handleVendorChange = (event) => {
      const newVendorId = event.target.value;
      if (newVendorId === "add_shop") {
        const name = prompt("Enter your new shop name");
        if (name) {
          createVendor(name);
        }
      } else {
        const selectedVendor = vendors.find((v) => v.id === newVendorId);
        selectVendor(selectedVendor);
      }
    };

    return (
      <Select value={vendor?.id || ""} onChange={handleVendorChange} displayEmpty fullWidth>
        {vendors.map((v) => (
          <MenuItem key={v.id} value={v.id}>
            <ListItem button className={classes.profileContainer}>
              <Avatar src={v.company_photo && v.company_photo} />
              <div style={{ overflow: "hidden" }}>
                {vendor?.id === v.id && (
                  <Typography variant="body2" color="textSecondary">
                    Hi there,
                  </Typography>
                )}
                <Typography
                  variant="body1"
                  style={{ fontWeight: 600, textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {v?.vendor_name}
                </Typography>
              </div>
            </ListItem>
          </MenuItem>
        ))}
        {isAdmin(user) && (
          <MenuItem value="add_shop">
            <ListItem button className={classes.profileContainer}>
              <Avatar>
                <Add />
              </Avatar>
              <Typography variant="body1" style={{ fontWeight: 600 }}>
                Add New Shop
              </Typography>
            </ListItem>
          </MenuItem>
        )}
      </Select>
    );
  };

  const handleLogout = () => {
    Auth.logout().then((res) => {
      history.push("/login");
      history.go();
    });
  };

  const handleDrawerToggle = (key) => {
    if (!drawerOpen) {
      setNestedMenuOpen("");
      setDrawerOpen(true);
      setTimeout(() => setNestedMenuOpen(key), 300);
    } else {
      setDrawerOpen(false);
    }
  };

  const handleToggleMobile = () => {
    if (isMobile) {
      setDrawerOpen(!drawerOpen);
    }
  };

  const handleNestedMenuToggle = (key) => {
    if (nestedMenuOpen !== key) {
      setNestedMenuOpen(key);
    }
  };

  const navigatingShopSettings = location?.pathname.includes("/shop");
  const navigatingProductSettings = location?.pathname.includes("/products");
  const navigatingUserSettings = location?.pathname.includes("/settings");

  const getMenuKey = () => {
    let key = "";
    if (navigatingShopSettings) {
      key = "shop";
    } else if (navigatingProductSettings) {
      key = "products";
    } else if (navigatingUserSettings) {
      key = "settings";
    }
    return key;
  };

  useEffect(() => {
    const key = getMenuKey();
    setTimeout(() => setNestedMenuOpen(key), 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isMobile && (
        <AppBar className={classes.appBar} elevation={3}>
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              onClick={() => {
                const key = getMenuKey();
                handleDrawerToggle(key);
              }}
            >
              <Menu fontSize="large" />
            </IconButton>

            <Button
              component={NavLink}
              to="/home/settings"
              className={classes.profileContainer}
              style={{ marginLeft: "auto" }}
            >
              <Avatar src={vendor.company_photo && vendor.company_photo} />
            </Button>
          </Toolbar>
        </AppBar>
      )}

      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        variant={isMobile ? "temporary" : "permanent"}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
        open={drawerOpen}
        anchor="left"
        onClose={() => handleDrawerToggle()}
      >
        <div className={classes.drawerContent}>
          {drawerOpen ? (
            <img className={classes.logo} src={Logo} alt="Carte" />
          ) : (
            <img className={classes.logo} src={LogoCollapsed} alt="Carte" />
          )}

          <Divider className={classes.divider} />

          <VendorSelector />

          <List component="nav" className={classes.list}>
            <ListItem
              component={NavLink}
              to="/home/shop/general"
              className={clsx(classes.listItem, {
                [classes.activeListItem]:
                  navigatingShopSettings && (!drawerOpen || !nestedMenuOpen),
                [classes.activeNestedMenuItem]: navigatingShopSettings && drawerOpen,
              })}
              button
              onClick={() => {
                if (!drawerOpen) {
                  handleDrawerToggle("shop");
                } else {
                  handleNestedMenuToggle("shop");
                }
              }}
            >
              <StorefrontTwoTone className={classes.listIcon} />
              <Typography variant="h5">Shop</Typography>
            </ListItem>
            <Collapse in={nestedMenuOpen === "shop" && drawerOpen} timeout={400} unmountOnExit>
              <List component="div" disablePadding className={classes.list}>
                <ListItem
                  component={NavLink}
                  to="/home/shop/general"
                  activeClassName={classes.activeListItem}
                  className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                  button
                  onClick={handleToggleMobile}
                >
                  <Typography variant="body1">General</Typography>
                </ListItem>
                <ListItem
                  component={NavLink}
                  to="/home/shop/landing"
                  activeClassName={classes.activeListItem}
                  className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                  button
                  onClick={handleToggleMobile}
                >
                  <Typography variant="body1">Landing Page</Typography>
                </ListItem>
                <ListItem
                  component={NavLink}
                  to="/home/shop/theme-layout"
                  activeClassName={classes.activeListItem}
                  className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                  button
                  onClick={handleToggleMobile}
                >
                  <Typography variant="body1">Theme & Layout</Typography>
                </ListItem>
                <ListItem
                  component={NavLink}
                  to="/home/shop/engagements"
                  activeClassName={classes.activeListItem}
                  className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                  button
                  onClick={handleToggleMobile}
                >
                  <Typography variant="body1">Engagements</Typography>
                </ListItem>
                <ListItem
                  component={NavLink}
                  to="/home/shop/social"
                  activeClassName={classes.activeListItem}
                  className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                  button
                  onClick={handleToggleMobile}
                >
                  <Typography variant="body1">Social Links</Typography>
                </ListItem>
                <ListItem
                  component={NavLink}
                  to="/home/shop/faqs"
                  activeClassName={classes.activeListItem}
                  className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                  button
                  onClick={handleToggleMobile}
                >
                  <Typography variant="body1">FAQs</Typography>
                </ListItem>
                {isAdmin(user) && (
                  <ListItem
                    component={NavLink}
                    to="/home/shop/custom-domain"
                    activeClassName={classes.activeListItem}
                    className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                    button
                    onClick={handleToggleMobile}
                  >
                    <Typography variant="body1">Custom Domain</Typography>
                  </ListItem>
                )}
              </List>
            </Collapse>

            <ListItem
              component={NavLink}
              to="/home/products"
              activeClassName={classes.activeListItem}
              className={clsx(classes.listItem, {
                [classes.activeListItem]:
                  navigatingProductSettings && (!drawerOpen || !nestedMenuOpen),
                [classes.activeNestedMenuItem]: navigatingProductSettings && drawerOpen,
              })}
              button
              onClick={() => {
                if (!drawerOpen) {
                  handleDrawerToggle("products");
                } else {
                  handleNestedMenuToggle("products");
                }
              }}
            >
              <LocalMallTwoTone className={classes.listIcon} />
              <Typography variant="h5">Products</Typography>
            </ListItem>
            <Collapse in={nestedMenuOpen === "products" && drawerOpen} timeout={300} unmountOnExit>
              <ListItem
                component={NavLink}
                to="/home/products/categories"
                activeClassName={classes.activeListItem}
                className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                button
                onClick={handleToggleMobile}
              >
                <Typography variant="body1">Categories</Typography>
              </ListItem>
              <ListItem
                component={NavLink}
                to="/home/products/layout"
                activeClassName={classes.activeListItem}
                className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                button
                onClick={handleToggleMobile}
              >
                <Typography variant="body1">Layout</Typography>
              </ListItem>
            </Collapse>

            <ListItem
              component={NavLink}
              to="/home/orders"
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              button
              onClick={() => {
                handleNestedMenuToggle("orders");
                handleToggleMobile();
              }}
            >
              <ListAltTwoTone className={classes.listIcon} />
              <Typography variant="h5">Orders</Typography>
            </ListItem>
            <ListItem
              component={NavLink}
              to="/home/delivery"
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              button
              onClick={() => {
                handleNestedMenuToggle("delivery");
                handleToggleMobile();
              }}
            >
              <LocalShippingTwoTone className={classes.listIcon} />
              <Typography variant="h5">Delivery</Typography>
            </ListItem>
            <ListItem
              component={NavLink}
              to="/home/promos"
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              button
              onClick={() => {
                handleNestedMenuToggle("promotions");
                handleToggleMobile();
              }}
            >
              <LocalOfferTwoTone className={classes.listIcon} />
              <Typography variant="h5">Promotion</Typography>
            </ListItem>
            <ListItem
              component={NavLink}
              to="/home/subscription"
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              button
              onClick={() => {
                handleNestedMenuToggle("premium");
                handleToggleMobile();
              }}
            >
              <StarsTwoTone className={classes.listIcon} />
              <Typography variant="h5">Premium</Typography>
            </ListItem>

            <ListItem
              component={NavLink}
              to="/home/settings/account"
              activeClassName={classes.activeListItem}
              className={clsx(classes.listItem, {
                [classes.activeListItem]:
                  navigatingUserSettings && (!drawerOpen || !nestedMenuOpen),
                [classes.activeNestedMenuItem]: navigatingUserSettings && drawerOpen,
              })}
              button
              onClick={() => {
                if (!drawerOpen) {
                  handleDrawerToggle("settings");
                } else {
                  handleNestedMenuToggle("settings");
                }
              }}
            >
              <SettingsTwoTone className={classes.listIcon} />
              <Typography variant="h5">Settings</Typography>
            </ListItem>
            <Collapse in={nestedMenuOpen === "settings" && drawerOpen} timeout={500} unmountOnExit>
              <ListItem
                component={NavLink}
                to="/home/settings/shop"
                activeClassName={classes.activeListItem}
                className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                button
                onClick={handleToggleMobile}
              >
                <Typography variant="body1">Shop</Typography>
              </ListItem>
              <ListItem
                component={NavLink}
                to="/home/settings/payments"
                activeClassName={classes.activeListItem}
                className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                button
                onClick={handleToggleMobile}
              >
                <Typography variant="body1">Payments</Typography>
              </ListItem>
              {isAdmin(user) && (
                <ListItem
                  component={NavLink}
                  to="/home/settings/api-keys"
                  activeClassName={classes.activeListItem}
                  className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                  button
                  onClick={handleToggleMobile}
                >
                  <Typography variant="body1">API Keys</Typography>
                </ListItem>
              )}
              {isAdmin(user) && (
                <ListItem
                  component={NavLink}
                  to="/home/settings/webhooks"
                  activeClassName={classes.activeListItem}
                  className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                  button
                  onClick={handleToggleMobile}
                >
                  <Typography variant="body1">Webhooks</Typography>
                </ListItem>
              )}
              <ListItem
                component={NavLink}
                to="/home/settings/account"
                activeClassName={classes.activeListItem}
                className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                button
                onClick={handleToggleMobile}
              >
                <Typography variant="body1">Account</Typography>
              </ListItem>
              {/* <ListItem
                component={NavLink}
                to="/home/settings/engagements"
                activeClassName={classes.activeListItem}
                className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                button
                onClick={handleToggleMobile}
              >
                <Typography variant="body1">Engagements</Typography>
              </ListItem> */}
              {/* <ListItem
                component={NavLink}
                to="/home/settings/social"
                activeClassName={classes.activeListItem}
                className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                button
                onClick={handleToggleMobile}
              >
                <Typography variant="body1">Social Links</Typography>
              </ListItem> */}
              {/* <ListItem
                component={NavLink}
                to="/home/settings/faq"
                activeClassName={classes.activeListItem}
                className={`${classes.listItem} ${classes.nestedLevel1ListItem}`}
                button
                onClick={handleToggleMobile}
              >
                <Typography variant="body1">FAQs</Typography>
              </ListItem> */}
            </Collapse>
          </List>

          <Divider className={classes.divider} />

          {!isMobile && (
            <IconButton
              size="small"
              className={classes.toggleIcon}
              onClick={() => {
                const key = getMenuKey();
                handleDrawerToggle(key);
              }}
            >
              {drawerOpen ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          )}

          <div className={classes.spacer} />
          <ListItem
            className={classes.bottomListItem}
            component="a"
            href={`${getShopDomain(shopfront)}`}
            target="_blank"
            referrerPolicy="no-referrer"
            button
          >
            <VisibilityTwoTone className={classes.listIcon} />
            <Typography variant="body1">View Shop</Typography>
          </ListItem>
          <ListItem
            className={`${classes.bottomListItem} ${classes.logout}`}
            button
            onClick={() => handleLogout()}
          >
            <ExitToApp className={classes.listIcon} />
            <Typography variant="body1">Logout</Typography>
          </ListItem>
        </div>
      </Drawer>
    </>
  );
};

export default SideNav;
