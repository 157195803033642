import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import PrivateRoute from "../../components/PrivateRoute";
import PageTitle from "../../components/PageTitle";
import AccountSettings from "./containers/AccountSettings";
import PaymentSettings from "./containers/PaymentSettings";
import ShopSettings from "./containers/ShopSettings";
import ApiKey from "./containers/ApiKey";
import Webhook from "./containers/Webhook";
// import EngagementSettings from "./containers/EngagementSettings";
// import FaqSettings from "./containers/FaqSettings";
// import SocialLinkSettings from "./containers/SocialLinkSettings";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 190px)", // padding: 30px * 2, title: 80px + 50px, total 190px
    marginBottom: 100,
    margin: 30,
  },
}));

const UserSettings = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>Settings | Carte</title>
      </Helmet>
      <div className={classes.root}>
        <PageTitle title="Settings" />
        <Switch>
          <PrivateRoute exact path="/home/settings/account" render={() => <AccountSettings />} />
          <PrivateRoute exact path="/home/settings/payments" render={() => <PaymentSettings />} />
          <PrivateRoute exact path="/home/settings/shop" render={() => <ShopSettings />} />
          <PrivateRoute exact path="/home/settings/api-keys" render={() => <ApiKey />} />
          <PrivateRoute exact path="/home/settings/webhooks" render={() => <Webhook />} />
          {/* <PrivateRoute exact path="/home/settings/engagements" render={() => <EngagementSettings />} /> */}
          {/* <PrivateRoute exact path="/home/settings/social" render={() => <SocialLinkSettings />} /> */}
          {/* <PrivateRoute exact path="/home/settings/faq" render={() => <FaqSettings />} /> */}
          <Redirect from="/home/settings" to="/home/settings/account" />
        </Switch>
      </div>
    </>
  );
};

export default UserSettings;
