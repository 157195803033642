import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useSnackbar } from "notistack";
import GenericContainer from "../../../components/containers/GenericContainer";
import useShopContext from "../../../hooks/useShopContext";
import { getWebhooks, createWebhook, updateWebhook, deleteWebhook } from "../api";
import LoadingSplashScreen from "../../../components/LoadingSplashScreen";

const EVENT_TYPES = ["order.created", "order.updated"];

const Webhook = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { shopfront, loading } = useShopContext();
  const [webhooks, setWebhooks] = useState([]);
  const [newUrl, setNewUrl] = useState("");
  const [newEvents, setNewEvents] = useState([]);
  const [editWebhook, setEditWebhook] = useState(null);
  const [editUrl, setEditUrl] = useState("");
  const [editEvents, setEditEvents] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (!loading && shopfront.vendor) {
      loadWebhooks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const loadWebhooks = () => {
    getWebhooks(shopfront.vendor.id)
      .then((res) => {
        setWebhooks(res.data);
      })
      .catch((err) => {
        enqueueSnackbar(`Error loading webhooks: ${err.response?.data || "Unknown error"}`, {
          variant: "error",
        });
      });
  };

  const isValidUrl = (url) => {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  };

  const handleCreateWebhook = () => {
    if (!newUrl.trim()) {
      enqueueSnackbar("Please enter a valid URL for the webhook", { variant: "error" });
      return;
    } else if (!isValidUrl(newUrl)) {
      enqueueSnackbar("Please enter a valid URL", { variant: "error" });
      return;
    }
    if (newEvents.length === 0) {
      enqueueSnackbar("Please select at least one event", { variant: "error" });
      return;
    }
    const events = newEvents.map((event) => ({ event_type: event }));
    createWebhook(shopfront.vendor.id, { url: newUrl, events: events })
      .then(() => {
        enqueueSnackbar("Webhook created successfully!", { variant: "success" });
        setNewUrl("");
        setNewEvents([]);
        loadWebhooks();
      })
      .catch((err) => {
        enqueueSnackbar(
          `Error creating webhook: ${err.response?.data?.detail || "Unknown error"}`,
          {
            variant: "error",
          },
        );
      });
  };

  const handleUpdateWebhook = () => {
    if (!editUrl.trim()) {
      enqueueSnackbar("Please enter a valid URL for the webhook", { variant: "error" });
      return;
    }
    if (editEvents.length === 0) {
      enqueueSnackbar("Please select at least one event", { variant: "error" });
      return;
    }

    const events = editEvents.map((event) => ({ event_type: event }));
    updateWebhook(shopfront.vendor.id, editWebhook.id, { url: editUrl, events: events })
      .then(() => {
        enqueueSnackbar("Webhook updated successfully!", { variant: "success" });
        setEditWebhook(null);
        setEditUrl("");
        setEditEvents([]);
        loadWebhooks();
      })
      .catch((err) => {
        enqueueSnackbar(`Error updating webhook: ${err.response?.data || "Unknown error"}`, {
          variant: "error",
        });
      });
  };

  const handleDeleteWebhook = (webhookId) => {
    deleteWebhook(shopfront.vendor.id, webhookId)
      .then(() => {
        enqueueSnackbar("Webhook deleted successfully!", { variant: "success" });
        loadWebhooks();
      })
      .catch((err) => {
        enqueueSnackbar(`Error deleting webhook: ${err.response?.data || "Unknown error"}`, {
          variant: "error",
        });
      });
  };

  // eslint-disable-next-line no-unused-vars
  const truncateUrl = (url, maxLength) => {
    if (url.length <= maxLength) return url;
    return url.slice(0, maxLength - 3) + "...";
  };

  return (
    <GenericContainer>
      {loading ? <LoadingSplashScreen /> : null}
      <Typography variant="h5" gutterBottom>
        Webhooks
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="New Webhook URL"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newUrl}
            onChange={(e) => setNewUrl(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Events</InputLabel>
            <Select
              multiple
              value={newEvents}
              onChange={(e) => setNewEvents(e.target.value)}
              renderValue={(selected) => (
                <div>
                  {selected.map((eventType, index) => (
                    <Chip key={index} label={eventType} style={{ margin: 2 }} />
                  ))}
                </div>
              )}
            >
              {EVENT_TYPES.map((event) => (
                <MenuItem key={event} value={event}>
                  {event}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleCreateWebhook}>
            Create New Webhook
          </Button>
        </Grid>

        <Grid item xs={12}>
          <List>
            {webhooks.map((webhook) => (
              <ListItem key={webhook.id} style={{ display: "flex", alignItems: "center" }}>
                {isEdit && webhook.id === editWebhook?.id ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          value={editUrl}
                          onChange={(e) => setEditUrl(e.target.value)}
                          fullWidth
                          variant="outlined"
                          size="small"
                          error={!editUrl.trim()}
                          helperText={!editUrl.trim() ? "Please enter a valid URL" : ""}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Events</InputLabel>
                          <Select
                            multiple
                            value={editEvents}
                            onChange={(e) => setEditEvents(e.target.value)}
                            renderValue={(selected) => (
                              <div>
                                {selected.map((eventType, index) => (
                                  <Chip key={index} label={eventType} style={{ margin: 2 }} />
                                ))}
                              </div>
                            )}
                          >
                            {EVENT_TYPES.map((event) => (
                              <MenuItem key={event} value={event}>
                                {event}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Button onClick={handleUpdateWebhook}>Save</Button>
                        <Button onClick={() => setEditWebhook(null)}>Cancel</Button>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <ListItemText
                      primary={webhook.url}
                      secondary={`${webhook.service_type}: ${webhook.events
                        .map((e) => e.event_type)
                        .join(", ")}`}
                      primaryTypographyProps={{
                        variant: "body1",
                        style: {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          flexGrow: 1, // Make the ListItemText take up remaining space
                        },
                      }}
                    />
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                      <IconButton
                        edge="end"
                        onClick={() => {
                          setEditWebhook(webhook);
                          setEditUrl(webhook.url);
                          setEditEvents(webhook.events.map((e) => e.event_type));
                          setIsEdit(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" onClick={() => handleDeleteWebhook(webhook.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </GenericContainer>
  );
};

export default Webhook;
