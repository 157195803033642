import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Paper, TextField, Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import _ from "lodash";

import useAuth from "../hooks/useAuth";
import { FRONTEND_URL } from "../config";
import StyledTypography from "../components/StyledTypography";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import PasswordInput from "./UserSettings/components/PasswordInput";

const styles = (theme) => ({
  main: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.dark,
    display: "grid",
    placeItems: "center",
  },
  paperRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: theme.spacing(1),
    margin: theme.spacing(1),
    padding: theme.spacing(4, 2),
    width: "40vw",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
    },
  },
  paperBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: theme.spacing(0.5),
    margin: theme.spacing(3, 0),
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  input: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.dark,
    borderRadius: "32px",
    border: `1px solid ${theme.palette.grey[400]}`,
    width: "100%",
    fontSize: 18,
    padding: "8px 15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      padding: "12px 10px",
    },
  },
  button: {
    borderRadius: 15,
    width: "100%",
    textTransform: "capitalize",
    margin: "10px 0",
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
  socialButton: {
    width: "100%",
    padding: "10px 15px",
    borderRadius: "4px",
    border: "none",
    fontSize: "16px",
    fontWeight: 500,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    backgroundColor: "#1877F2", // Facebook blue
    color: "white",
    "&:hover": {
      backgroundColor: "#166FE5", // Slightly darker shade for hover
    },
    "& span": {
      marginLeft: "8px",
    },
    boxShadow: theme.shadows[2],
    transition: "background-color 0.2s ease, box-shadow 0.2s ease",
    "&:active": {
      boxShadow: theme.shadows[1],
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "8px 12px",
    },
  },
  socialButtonsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
});

const LoginPage = (props) => {
  const { classes } = props;
  const history = useHistory();

  const { login, error, user, loginSocial } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setLoginDetails({
      ...loginDetails,
      email: e.target.value,
    });
  };

  const handlePasswordChange = (e) => {
    setLoginDetails({
      ...loginDetails,
      password: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await login({ ...loginDetails, email: loginDetails.email.toLowerCase() }).finally(() =>
      setLoading(false),
    );
  };

  // push to dashboard
  useEffect(() => {
    if (Cookies.get("t1")) {
      // console.log("logged in");
      history.push("/");
    }
  }, [history]);

  // when context updates
  useEffect(() => {
    if (!_.isEmpty(user) && !error) {
      history.push("/home/shop");
    }
    if (error) {
      if (error.response && error.response.status === 401) {
        enqueueSnackbar("Incorrect email address or password. Please try again!", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        });
      } else {
        enqueueSnackbar(
          `Something went wrong. Kindly contact us with the error code: ${
            error.response && error.response.status
          }`,
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          },
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, error]);

  const handleLoginSuccess = async (response) => {
    setLoading(true);
    try {
      // Send the ID token to your backend
      await loginSocial(response, "google");
      history.push("/home/shop");
    } catch (error) {
      enqueueSnackbar("Failed to login with Google. Please try again.", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLoginFailure = (response) => {
    console.error(response);
    enqueueSnackbar("Google login failed. Please try again.", {
      variant: "error",
      anchorOrigin: { vertical: "bottom", horizontal: "left" },
    });
  };

  const responseFacebook = async (response) => {
    if (response.status === "unknown") {
      enqueueSnackbar("Facebook login cancelled.", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
      return;
    }

    setLoading(true);
    try {
      await loginSocial(response, "facebook");
      history.push("/home/shop");
    } catch (error) {
      enqueueSnackbar("Failed to login with Facebook. Please try again.", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Login | Carte</title>
        <link rel="canonical" href={`${FRONTEND_URL}`} />
      </Helmet>
      <div className={classes.main}>
        <form onSubmit={handleSubmit}>
          <Paper elevation={3} className={classes.paperRoot}>
            <Typography variant="h1" style={{ fontWeight: 500, marginTop: "12px" }}>
              <StyledTypography>LOGIN</StyledTypography>
            </Typography>
            <Typography variant="h6" gutterBottom>
              Welcome back, we've missed you!
            </Typography>

            <div className={classes.paperBody}>
              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                placeholder="Email"
                value={loginDetails && loginDetails.email}
                onChange={handleEmailChange}
                type="email"
                required
                autoFocus
              />

              <PasswordInput
                placeholder="Password"
                value={loginDetails && loginDetails.password}
                onChange={handlePasswordChange}
              />

              <Button variant="contained" color="primary" className={classes.button} type="submit">
                {loading ? <CircularProgress size="1.5rem" color="inherit" /> : "Login"}
              </Button>
              <div className={classes.socialButtonsContainer}>
                {process.env.REACT_APP_GOOGLE_CLIENT_ID && (
                  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <div>
                      <GoogleLogin onSuccess={handleLoginSuccess} onFailure={handleLoginFailure} />
                    </div>
                  </GoogleOAuthProvider>
                )}
                {process.env.REACT_APP_FACEBOOK_APP_ID && (
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    icon="fa-facebook"
                    cssClass={classes.socialButton}
                    textButton="Continue with Facebook"
                    size="small"
                  />
                )}
              </div>
              <div style={{ maxWidth: "90%" }}>
                <Typography variant="body2">
                  <Link to="/reset-password" className={classes.link}>
                    Forgot password
                  </Link>
                </Typography>
              </div>
              <Typography variant="body1" color="textSecondary" style={{ marginTop: 24 }}>
                Do not have an account? Click{" "}
                <Link to="/register" className={classes.link}>
                  here
                </Link>{" "}
                to register!
              </Typography>
            </div>
          </Paper>
        </form>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(LoginPage);
