import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useSnackbar } from "notistack";
import GenericContainer from "../../../components/containers/GenericContainer";
import useShopContext from "../../../hooks/useShopContext";
import { getApiKeys, createApiKey, updateApiKey, deleteApiKey } from "../api";
import LoadingSplashScreen from "../../../components/LoadingSplashScreen";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ApiKey = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { shopfront, loading } = useShopContext();
  const [apiKeys, setApiKeys] = useState([]);
  const [newKeyName, setNewKeyName] = useState("");
  const [editKey, setEditKey] = useState(null);
  const [editName, setEditName] = useState("");

  useEffect(() => {
    if (!loading) {
      loadApiKeys();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const loadApiKeys = () => {
    getApiKeys(shopfront.vendor.id)
      .then((res) => {
        setApiKeys(res.data);
      })
      .catch((err) => {
        enqueueSnackbar(`Error loading API keys: ${err.response?.data || "Unknown error"}`, {
          variant: "error",
        });
      });
  };

  const handleCreateKey = () => {
    if (!newKeyName.trim()) {
      enqueueSnackbar("Please enter a name for the API key", { variant: "error" });
      return;
    }

    createApiKey(shopfront.vendor.id, newKeyName)
      .then(() => {
        enqueueSnackbar("API key created successfully!", { variant: "success" });
        setNewKeyName("");
        loadApiKeys();
      })
      .catch((err) => {
        enqueueSnackbar(`Error: ${err.response?.data?.detail || "Unknown error"}`, {
          variant: "error",
        });
      });
  };

  const handleUpdateKey = () => {
    if (!editName.trim()) {
      enqueueSnackbar("Please enter a name for the API key", { variant: "error" });
      return;
    }

    updateApiKey(shopfront.vendor.id, editKey.key, editName)
      .then(() => {
        enqueueSnackbar("API key updated successfully!", { variant: "success" });
        setEditKey(null);
        setEditName("");
        loadApiKeys();
      })
      .catch((err) => {
        enqueueSnackbar(`Error: ${err.response?.data?.detail || "Unknown error"}`, {
          variant: "error",
        });
      });
  };

  const handleDeleteKey = (apiKey) => {
    deleteApiKey(shopfront.vendor.id, apiKey)
      .then(() => {
        enqueueSnackbar("API key deleted successfully!", { variant: "success" });
        loadApiKeys();
      })
      .catch((err) => {
        enqueueSnackbar(`Error: ${err.response?.data || "Unknown error"}`, { variant: "error" });
      });
  };

  const handleCopy = () => {
    enqueueSnackbar("copied to clipboard!", { variant: "success" });
  };

  return (
    <GenericContainer>
      {loading ? <LoadingSplashScreen /> : null}
      <Typography variant="h5" gutterBottom>
        API Keys
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="New API Key Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newKeyName}
            onChange={(e) => setNewKeyName(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handleCreateKey}>
            Create New API Key
          </Button>
        </Grid>

        <Grid item xs={12}>
          <List>
            {apiKeys.map((key) => (
              <ListItem key={key.key} style={{ display: "flex", alignItems: "center" }}>
                {editKey?.key === key.key ? (
                  <>
                    <TextField
                      value={editName}
                      onChange={(e) => setEditName(e.target.value)}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                    <Button onClick={handleUpdateKey}>Save</Button>
                    <Button onClick={() => setEditKey(null)}>Cancel</Button>
                  </>
                ) : (
                  <>
                    <ListItemText
                      primary={key.key}
                      secondary={key.name}
                      primaryTypographyProps={{
                        variant: "body1",
                        style: {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          flexGrow: 1, // Make the ListItemText take up remaining space
                        },
                      }}
                    />
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                      <CopyToClipboard text={key.key} onCopy={handleCopy}>
                        <IconButton edge="end">
                          <FileCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                      <IconButton
                        edge="end"
                        onClick={() => {
                          setEditKey(key);
                          setEditName(key.name);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" onClick={() => handleDeleteKey(key.key)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </GenericContainer>
  );
};

export default ApiKey;
