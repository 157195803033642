import React, { useState, createContext, useMemo, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  login as loginApi,
  loginAdmin as loginAdminApi,
  loginSocial as loginSocialApi,
  logout as logoutApi,
  getJWT,
  getVendorId,
  setVendorId,
  refetchVendors,
  refetchVendor,
  createVendor as createVendorApi,
} from "../Auth";

const initialContext = {
  user: {},
  vendor: {},
  loading: false,
  error: false,
  login: () => {},
  logout: () => {},
  refresh: () => {},
};

export const AuthContext = createContext(initialContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [vendors, setVendors] = useState([]);
  const [vendor, setVendor] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // const flattenVendor = (vendor) => {
  //   setVendor(vendor);
  //   setUser(vendor.user);
  //   // const { user, ..._vendor } = vendor;
  //   // return { ..._vendor, ...user };
  // };

  const flattenData = (data) => {
    setUser(data.user);
    const vendorId = getVendorId();
    const vendors = data.vendors || [];
    setVendors(vendors);
    if (vendorId) {
      const vendor = vendors.find((v) => v.id === vendorId);
      if (vendor) {
        setVendor(vendor);
      } else {
        setVendor(data.vendor);
      }
    } else {
      setVendor(data.vendor);
    }
  };

  useEffect(() => {
    const id = getJWT();
    if (id && _.isEmpty(vendor)) {
      setLoading(true);
      refetchVendors()
        .then((res) => flattenData(res.data.user))
        .finally(() => {
          setLoading(false);
          setError(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (user) => {
    setLoading(true);
    await loginApi(user)
      .then((res) => flattenData(res.data.user))
      .catch((err) => setError(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          setError(false);
        }, 1500);
      });
  };

  const loginAdmin = async (email) => {
    setLoading(true);
    await loginAdminApi(email)
      .then((res) => flattenData(res.data.user))
      .catch((err) => setError(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          setError(false);
        }, 1500);
      });
  };

  const loginSocial = async (data, type = "google") => {
    setLoading(true);
    await loginSocialApi(data, type)
      .then((res) => flattenData(res.data.user))
      .catch((err) => setError(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          setError(false);
        }, 1500);
      });
  };

  const logout = () => {
    logoutApi();
  };

  const refresh = async () => {
    setLoading(true);
    await refetchVendor(vendor.id)
      .then((res) => {
        setVendor(res.data);
        setVendors((prevVendors) => prevVendors.map((v) => (v.id === res.data.id ? res.data : v)));
      })
      .catch((err) => setError(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          setError(false);
        }, 1500);
      });
  };

  const createVendor = async (name) => {
    setLoading(true);
    await createVendorApi(user.id, name)
      .then((res) => {
        const newVendor = res.data;
        setVendor(newVendor);
        setVendors([...vendors, newVendor]);
        setVendorId(newVendor.id);
      })
      .catch((err) => setError(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          setError(false);
        }, 1500);
      });
  };

  const selectVendor = (newVendor) => {
    if (newVendor.id !== vendor.id) {
      setLoading(true);
      setVendor(newVendor);
      setVendorId(newVendor.id);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const memoizedContext = useMemo(
    () => ({
      user: user,
      setUser: setUser,
      vendors: vendors,
      vendor: vendor,
      setVendor: setVendor,
      createVendor: createVendor,
      selectVendor: selectVendor,
      loading: loading,
      error: error,
      login: login,
      loginAdmin: loginAdmin,
      loginSocial: loginSocial,
      logout: logout,
      refresh: refresh,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, vendor, loading, error],
  );

  return <AuthContext.Provider value={memoizedContext}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
