import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography, FormControlLabel, Checkbox } from "@material-ui/core";

import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import ChangePassword from "../components/ChangePassword";
import GenericContainer from "../../../components/containers/GenericContainer";

import useAuth from "../../../hooks/useAuth";
import { updateUser } from "../api";
import LoadingSplashScreen from "../../../components/LoadingSplashScreen";
import { isAdmin } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  detailsSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0),
  },
  button: {
    boxShadow: "none",
  },
  mobileField: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  infoIcon: {
    marginLeft: "8px",
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
  },
  primaryButton: {
    width: 150,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: theme.spacing(1, 0),
  },

  image: {
    height: "150px",
    objectFit: "contain",
  },
  deleteBtn: {
    position: "relative",
    bottom: 145,
    right: 18,
    height: 36,
    width: 35,
    [theme.breakpoints.down("sm")]: {
      right: 11,
      width: 20,
    },
  },
  previewContainer: {
    width: "100%",
    margin: 0,
  },
  previewGridItem: {
    maxWidth: "100%",
    flexBasis: "auto",
    padding: 0,
  },
  dropzoneContainer: {
    margin: theme.spacing(1, 0),
    width: "50%",
    maxWidth: 250,
    minHeight: 70,
    height: 110,
    // padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      height: 95,
    },
  },
  dropzoneParagraph: {
    fontSize: 14,
    padding: theme.spacing(0, 1),
  },
}));

const AccountSettings = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { user, setUser, loginAdmin, loading } = useAuth();

  const handleInputChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateDetails = () => {
    updateUser(user)
      .then((res) => {
        setUser(res.data);

        enqueueSnackbar("Details updated successfully!", { variant: "success" });
      })
      .catch((err) => {
        // console.log(err);
        enqueueSnackbar("Failed to update details!", { variant: "error" });
      });
    // .finally(() => refresh());
  };

  const handleSwitchUser = () => {
    const email = window.prompt("Enter Email or subdomain");
    if (!email) return;
    loginAdmin(email)
      .then((res) => {
        // console.log(res);
        enqueueSnackbar("Logged in successfully!", { variant: "success" });
        history.push("/home/shop");
        history.go();
      })
      .catch((err) => {
        // console.log(err);
        enqueueSnackbar("Login Failed!", { variant: "error" });
      });
  };

  return (
    <GenericContainer>
      {loading ? <LoadingSplashScreen /> : null}
      <div className={classes.detailsSection}>
        <Typography variant="h5" className={classes.header}>
          Account
        </Typography>
        <TextField
          id="name"
          label="Name"
          variant="outlined"
          margin="dense"
          autoComplete="off"
          type="text"
          name="name"
          value={user.name ? user.name : ""}
          onChange={handleInputChange}
        />
        <TextField
          id="email"
          variant="outlined"
          margin="dense"
          autoComplete="off"
          type="email"
          name="email"
          label="Email"
          value={user.email ? user.email : ""}
          onChange={handleInputChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={user.consent_to_marketing ? user.consent_to_marketing : false}
              onChange={(e) => {
                setUser({ ...user, consent_to_marketing: e.target.checked });
              }}
            />
          }
          label="I'd like to receive occasional marketing content via email."
        />
        <div className={classes.buttonContainer}>
          <Button
            className={classes.primaryButton}
            variant="contained"
            color="primary"
            onClick={handleUpdateDetails}
          >
            Save Details
          </Button>
        </div>
      </div>
      <ChangePassword />
      {isAdmin(user) && (
        <div>
          <Button
            className={classes.primaryButton}
            variant="contained"
            color="primary"
            onClick={handleSwitchUser}
          >
            Switch User
          </Button>
        </div>
      )}
    </GenericContainer>
  );
};

export default AccountSettings;
