import React from "react";
import { Button, makeStyles, Typography, CircularProgress } from "@material-ui/core";

import { redirectPaypalConnectOnboarding, updatePaymentMethods } from "../api";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "24px 0 16px 36px",
    [theme.breakpoints.down("sm")]: {
      margin: "24px 8px 16px 36px",
    },
  },
  statusContainer: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1),
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2, 0),
  },
  checkbox: {
    padding: "4px 9px",
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const PaypalConnectContainer = ({ vendorId, vendorAccount, paymentMethods }) => {
  const classes = useStyles();

  const renderStatus = () => {
    if (vendorAccount.details_submitted) {
      return <span className={classes.success}>Connected</span>;
    }
    if (vendorAccount.details_submitted === false) {
      return <span className={classes.error}>Incomplete</span>;
    }
    return <span className={classes.error}>Not Connected</span>;
  };

  const [loading, setLoading] = React.useState(false);

  const handleSetUpStripe = () => {
    setLoading(true);
    updatePaymentMethods(vendorId, { payment_methods: [paymentMethods.PP] })
      .then(() => {
        return redirectPaypalConnectOnboarding(vendorId);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.statusContainer}>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Status: {renderStatus()}
        </Typography>
        {vendorAccount.details_submitted ? (
          <Button
            variant="contained"
            size="small"
            href="https://paypal.com"
            target="_blank"
            referrer="noreferrer"
          >
            Manage
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSetUpStripe}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Set Up"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PaypalConnectContainer;
