import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";

import ActivationEmail from "./ActivationEmail";
import Auth from "../Auth";
import useQuery from "../hooks/useQuery";
import { FRONTEND_URL } from "../config";
import StyledTypography from "../components/StyledTypography";
import PasswordInput from "./UserSettings/components/PasswordInput";

const styles = (theme) => ({
  main: {
    height: "100vh",
    backgroundColor: theme.palette.background.dark,
    overflow: "hidden",
    overflowY: "auto",
    display: "grid",
    placeItems: "center",
  },
  paperRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: theme.spacing(1),
    margin: theme.spacing(1),
    padding: theme.spacing(4, 2),
    width: "40vw",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
    },
  },
  paperBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: theme.spacing(0.5),
    margin: theme.spacing(3, 0),
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  underline: {
    fontWeight: "bold",
    paddingBottom: "1px",
    borderBottomStyle: "solid",
    borderBottomWidth: " 5px",
    width: "fit-content",
    borderBottomColor: theme.palette.secondary.main,
    lineHeight: "0.6",
    display: "inline-block",
    color: theme.palette.primary.main,
  },
  button: {
    borderRadius: 15,
    width: "100%",
    height: 36,
    textTransform: "capitalize",
    margin: "10px 0",
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
});

const RegisterPage = (props) => {
  const { classes } = props;
  const history = useHistory();
  const query = useQuery();

  const [loading, setLoading] = useState(false);

  const [registerDetails, setRegisterDetails] = useState({
    email: "",
    password: "",
    name: "",
    consent_to_marketing: false,
  });
  const [retypePassword, setRetypePassword] = useState("");
  const [displayEmailActivation, setDisplayEmailActivation] = useState(false);
  const [referralCode, setReferralCode] = useState(query.get("ref") || null);

  const { enqueueSnackbar } = useSnackbar();

  // push to dashboard
  useEffect(() => {
    if (Cookies.get("t1")) {
      // console.log("logged in");
      history.push("/");
    }
  }, [history]);

  const handleInputChange = (e) => {
    setRegisterDetails({
      ...registerDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleRetypePasswordChange = (e) => {
    setRetypePassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (retypePassword !== registerDetails.password) {
      enqueueSnackbar("Passwords do not match! Please re-enter.", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
      setLoading(false);
      return;
    }

    Auth.register(registerDetails, referralCode)
      .then((res) => {
        setDisplayEmailActivation(true);
        // console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
        if (err.response.data) {
          enqueueSnackbar(err.response.data, {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
        } else {
          enqueueSnackbar(
            `Something went wrong. Kindly contact us with the error code: ${err.response.status}`,
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" },
            },
          );
        }
      });
  };

  return (
    <div>
      <Helmet>
        <title>Register An Account | Carte</title>
        <link rel="canonical" href={`${FRONTEND_URL}`} />
      </Helmet>
      {!displayEmailActivation ? (
        <div className={classes.main}>
          <form onSubmit={handleSubmit}>
            <Paper elevation={3} className={classes.paperRoot}>
              <Typography
                variant="h1"
                style={{
                  fontWeight: 500,
                }}
              >
                Welcome to <StyledTypography>Carte</StyledTypography>
              </Typography>
              <Typography variant="h6" gutterBottom>
                Start selling online today!
              </Typography>
              <div className={classes.paperBody}>
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  placeholder="Name"
                  value={registerDetails && registerDetails.username}
                  onChange={handleInputChange}
                  type="text"
                  name="name"
                  required
                  autoFocus
                />

                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  placeholder="Email"
                  value={registerDetails && registerDetails.email}
                  onChange={handleInputChange}
                  type="email"
                  name="email"
                  required
                />

                <PasswordInput
                  placeholder="Password"
                  value={registerDetails && registerDetails.password}
                  onChange={handleInputChange}
                  name="password"
                />

                <PasswordInput
                  placeholder="Re-type Password"
                  value={retypePassword && retypePassword}
                  onChange={handleRetypePasswordChange}
                />

                <div style={{ width: "100%" }}>
                  <FormControlLabel
                    style={{ fontSize: 8, textAlign: "left", width: "100%" }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={referralCode !== null}
                        onChange={(e) => {
                          console.log(e.target.checked);
                          setReferralCode(e.target.checked ? "" : null);
                        }}
                      />
                    }
                    label={<Typography variant="body2">I have a referral code</Typography>}
                  />
                  {referralCode !== null && (
                    <TextField
                      style={{ marginBottom: 8 }}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      placeholder="Referral Code"
                      value={referralCode}
                      onChange={(e) => setReferralCode(e.target.value)}
                    />
                  )}

                  <FormControlLabel
                    style={{ fontSize: 8, textAlign: "left", width: "100%" }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={registerDetails.consent_to_marketing}
                        onChange={(e) =>
                          setRegisterDetails({
                            ...registerDetails,
                            consent_to_marketing: e.target.checked,
                          })
                        }
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Receive occasional marketing content and updates via email.
                      </Typography>
                    }
                  />
                </div>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                >
                  {loading ? <CircularProgress size="1.5rem" color="inherit" /> : "Register"}
                </Button>
              </div>

              <div style={{ maxWidth: "90%" }}>
                <Typography variant="body1" color="textSecondary">
                  Already have an account? Click{" "}
                  <Link to="/login" className={classes.link}>
                    here
                  </Link>{" "}
                  to login!
                </Typography>
              </div>
            </Paper>
          </form>
        </div>
      ) : (
        <ActivationEmail />
      )}
    </div>
  );
};

export default withStyles(styles)(RegisterPage);
