import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  ButtonGroup,
  Chip,
  InputAdornment,
  Menu,
  MenuItem,
  Switch,
  TextField,
} from "@material-ui/core";
import { Add, MoreVert, Search } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { debounce } from "lodash";

import TableListing from "../../../components/TableListing";
import ScheduleReleaseDialog from "./ScheduleReleaseDialog";
import ExportProductsDialog from "./ExportProductsDialog";
import DeleteProductDialog from "../../CreateProduct/components/DeleteProductDialog";

import useAuth from "../../../hooks/useAuth";
import { fetchProducts, updateProductStatus } from "../api";
import { deleteProduct, duplicateProduct } from "../../CreateProduct/api";
import { hasFeature } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    background: theme.palette.common.white,
    margin: theme.spacing(0.5, 0),
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  row: {
    width: "100%",
    display: "flex",
    margin: theme.spacing(1, 0),
    gap: theme.spacing(1),
    justifyContent: "flex-end",
    alignItems: "center",
  },
  iconButton: {
    height: 40,
    background: theme.palette.common.white,
    minWidth: 0,
    margin: theme.spacing(0.5, 0),
    padding: 5,
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  primaryButton: {
    height: 40,
    boxShadow: "none",
  },
  icon: {
    color: theme.palette.grey[600],
  },
  buttonBase: {
    width: 80,
  },
  menuItem: {
    whiteSpace: "normal",
  },
  chip: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    marginLeft: theme.spacing(1.5),
  },
}));

const ProductsTable = () => {
  const classes = useStyles();
  const { user, vendor, loading: vendorLoading } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date_created");
  const [pageNum, setPageNum] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [loading, setLoading] = useState(true);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [scheduleRelease, setScheduleRelease] = useState(false);
  const [exportProductsDialogOpen, setExportProductsDialogOpen] = useState(false);

  const actionButtons = (row) => {
    return (
      <ButtonGroup variant="text" size="small">
        <Button
          className={classes.buttonBase}
          variant="contained"
          color="primary"
          disableElevation
          component={Link}
          to={`/home/products/${row.product_id}`}
        >
          Edit
        </Button>
      </ButtonGroup>
    );
  };

  const updateProduct = (id, is_active) => {
    const updatedRows = rows.map((product) =>
      product.product_id === id ? { ...product, is_active: is_active } : product,
    );
    setRows(updatedRows);
  };

  const activateProductToggle = (product) => (
    <Switch
      color="primary"
      checked={product.is_active}
      onChange={() => {
        const changeActivationOfProduct = {
          is_active: !product.is_active,
        };
        setLoading(true);
        updateProductStatus(product, changeActivationOfProduct)
          .then((res) => {
            // console.log(res);
            // getUserProducts(searchValue);
            updateProduct(product.product_id, !product.is_active);
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data, { variant: "error" });
          })
          .finally(() => setLoading(false));
      }}
    />
  );

  const renderInventory = (product) => {
    if (product.use_variants) {
      return (
        <span style={{ whiteSpace: "pre-line" }}>
          {product.variants.reduce((prev, curr) => {
            return (
              prev + `${curr.title}: ${Number.isInteger(curr.inventory) ? curr.inventory : "NA"}\n`
            );
          }, "")}
        </span>
      );
    }
    return <>{product.inventory}</>;
  };

  const MoreActions = ({ product }) => {
    const [subMenuAnchor, setSubMenuAnchor] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const handleDuplicateProduct = () => {
      setLoading(true);
      duplicateProduct(product?.vendor_id, product?.product_id)
        .then((res) => {
          // console.log(res);
          enqueueSnackbar("Product duplicated", { variant: "success" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(`Error occurred while duplicating this product`, { variant: "error" });
        })
        .finally(() => {
          setSubMenuAnchor(null);
          getUserProducts(searchValue);
          setDeleteDialogOpen(false);
        });
    };

    const handleDeleteProduct = () => {
      setLoading(true);
      deleteProduct(product?.vendor_id, product?.product_id)
        .then((res) => {
          // console.log(res);
          enqueueSnackbar("Product deleted", { variant: "success" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(`Error occurred while deleting this product`, { variant: "error" });
        })
        .finally(() => {
          setSubMenuAnchor(null);
          getUserProducts(searchValue);
        });
    };

    return (
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={classes.iconButton}
          onClick={(e) => setSubMenuAnchor(e.currentTarget)}
          disableRipple
          disableFocusRipple
        >
          <MoreVert className={classes.icon} />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={subMenuAnchor}
          keepMounted
          open={Boolean(subMenuAnchor)}
          onClose={() => setSubMenuAnchor(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
        >
          <MenuItem onClick={handleDuplicateProduct}>Duplicate Product</MenuItem>
          <MenuItem onClick={() => setDeleteDialogOpen(true)}>Delete Product</MenuItem>
        </Menu>
        <DeleteProductDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          handleDelete={handleDeleteProduct}
        />
      </>
    );
  };

  const headCells = [
    {
      id: "public_product_id",
      numeric: false,
      disablePadding: true,
      label: "ID",
      width: "8%",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "Product Name",
      sort: true,
      width: "20%",
    },
    {
      id: "inventory",
      numeric: false,
      disablePadding: true,
      label: "Inventory",
      sort: false,
      isFunction: {
        function: renderInventory,
      },
      width: "15%",
    },
    {
      id: "date_created",
      numeric: false,
      disablePadding: true,
      label: "Created",
      sort: true,
      date: true,
      width: "10%",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: true,
      label: "Actions",
      sort: false,
      isFunction: {
        function: actionButtons,
      },
      width: "10%",
    },
    {
      id: "is_active",
      numeric: false,
      disablePadding: true,
      label: "Active",
      sort: false,
      isFunction: {
        function: activateProductToggle,
      },
      width: "7%",
    },
    {
      id: "is_active",
      numeric: false,
      disablePadding: true,
      label: "",
      sort: false,
      component: MoreActions,
      width: "3%",
    },
  ];

  const getUserProducts = (searchTerm) => {
    let queryParams = {};

    if (order === "desc") {
      if (orderBy === "title") {
        queryParams = {
          page: pageNum + 1,
          sortTitle: -1,
          search: searchTerm,
        };
      } else if (orderBy === "inventory") {
        queryParams = {
          page: pageNum + 1,
          sortInventory: -1,
          search: searchTerm,
        };
      } else if (orderBy === "date_created") {
        queryParams = {
          page: pageNum + 1,
          sortDateCreated: -1,
          search: searchTerm,
        };
      } else if (orderBy === "order") {
        queryParams = {
          page: pageNum + 1,
          sortOrder: -1,
          search: searchTerm,
        };
      } else {
        queryParams = {
          page: pageNum + 1,
          sortId: -1,
          search: searchTerm,
        };
      }
    } else {
      if (orderBy === "title") {
        queryParams = {
          page: pageNum + 1,
          sortTitle: 1,
          search: searchTerm,
        };
      } else if (orderBy === "inventory") {
        queryParams = {
          page: pageNum + 1,
          sortInventory: 1,
          search: searchTerm,
        };
      } else if (orderBy === "date_created") {
        queryParams = {
          page: pageNum + 1,
          sortDateCreated: 1,
          search: searchTerm,
        };
      } else if (orderBy === "order") {
        queryParams = {
          page: pageNum + 1,
          sortOrder: 1,
          search: searchTerm,
        };
      } else {
        queryParams = {
          page: pageNum + 1,
          sortId: 1,
          search: searchTerm,
        };
      }
    }
    setLoading(true);
    fetchProducts(vendor.id, queryParams)
      .then((res) => {
        // console.log(res);
        setRows(res.data.results);
        setTotalCount(res.data.count);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!vendorLoading) {
      getUserProducts(searchValue);
    } // eslint-disable-next-line
  }, [order, orderBy, pageNum, vendorLoading]);

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      getUserProducts(searchTerm);
    }, 1500),
    [],
  );

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    debouncedSearch(event.target.value);
  };

  return (
    <>
      <div className={classes.row}>
        <TextField
          variant="outlined"
          margin="dense"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="disabled" />
              </InputAdornment>
            ),
          }}
          className={classes.searchBar}
          size="small"
          value={searchValue}
          onChange={handleSearch}
        />
        <Button
          component={Link}
          to="/home/products/new"
          variant="contained"
          color="primary"
          disableRipple
          disableFocusRipple
          startIcon={<Add />}
          className={classes.primaryButton}
        >
          New Product
        </Button>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          variant="outlined"
          className={classes.iconButton}
          onClick={(e) => setMenuAnchor(e.currentTarget)}
          disableRipple
          disableFocusRipple
        >
          <MoreVert className={classes.icon} />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
        >
          <MenuItem
            onClick={() => {
              setExportProductsDialogOpen(true);
              setMenuAnchor(null);
            }}
            className={classes.menuItem}
          >
            Export Products for Meta/Instagram Shop
          </MenuItem>
          <MenuItem
            onClick={() => {
              setScheduleRelease(true);
              setMenuAnchor(null);
            }}
            disabled={!hasFeature("product_schedule_release", user.features)}
            className={classes.menuItem}
          >
            Schedule Release for Products
            <Chip
              variant="outlined"
              size="small"
              label="Premium"
              classes={{ root: classes.chip }}
            />
          </MenuItem>
        </Menu>
      </div>
      <div style={{ marginTop: 8 }}>
        <TableListing
          headCells={headCells}
          rows={rows}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          pageNum={pageNum}
          setPageNum={setPageNum}
          totalCount={totalCount}
          variable="Products"
          loading={loading}
        />
      </div>

      <ExportProductsDialog
        open={exportProductsDialogOpen}
        onClose={() => setExportProductsDialogOpen(false)}
      />
      {scheduleRelease && (
        <ScheduleReleaseDialog
          vendorId={vendor?.id}
          open={scheduleRelease}
          onClose={() => setScheduleRelease(false)}
        />
      )}
    </>
  );
};

export default ProductsTable;
