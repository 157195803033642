import { SHOP_DOMAIN, IS_DEVELOPMENT } from "./config";

export function getFontColorByHex(h) {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  let count = 0;
  if (r < 100) count++;
  if (g < 100) count++;
  if (b < 100) count++;

  return count > 1 ? "#FFFFFF" : "#000";
}

export function isDark(h) {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  let count = 0;
  if (r < 100) count++;
  if (g < 100) count++;
  if (b < 100) count++;

  return count > 1 ? true : false;
}

export function hexToRGB(h) {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return { r: r, g: g, b: b };
}

export function hexToTransparentRGB(h) {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}, 0.2)`;
}

export function getReferrer(url) {
  if (typeof url !== "string" || !(url instanceof String)) {
    return "OT";
  }
  const urlList = url.split(".");
  for (const item of urlList) {
    switch (item.toLowerCase()) {
      case "instagram":
        return "IN";
      case "facebook":
        return "FB";
      case "reddit":
        return "RD";
      case "twitter":
        return "TW";
      case "google":
        return "GG";
      default:
        break;
    }
  }

  return "OT";
}

export function parseDate(dateString) {
  const date = new Date(dateString.split(".")[0]);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

export function toISODateString(date) {
  return date.toISOString().slice(0, 10);
}

export function timeConvert(time) {
  // function to convert time from 24HR format to 12HR
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }

  time.splice(3, 1); // remove seconds
  return time.join(""); // return adjusted time or original string
}

export function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  function convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "\uFEFF";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const isEmptyObj = (obj = {}) => {
  for (const i in obj) {
    return false;
  }
  return true;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const hasFeature = (feature, features) => {
  if (features && features.hasOwnProperty(feature)) {
    const value = features[feature];
    if (typeof value === "boolean") {
      return value;
    }
    return value === -1 || value > 0;
  }
  return false;
};

export const isAdmin = (user) => {
  return user?.privileges?.includes("admin");
};

export const getShopDomain = (shop) => {
  const scheme = IS_DEVELOPMENT ? "http://" : "https://";
  if (shop) {
    if (shop.custom_domain) {
      return scheme + shop.custom_domain.domain;
    }
    if (shop.subdomain) {
      return scheme + shop.subdomain + "." + SHOP_DOMAIN;
    }
  }
  return scheme + SHOP_DOMAIN;
};
