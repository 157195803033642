import jwtDecode from "jwt-decode";

import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";

export const updateVendor = (vendor) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/vendors/${vendor.id}`, vendor);
};

export const updatePassword = (userId, formData) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/users/${userId}`, formData);
};

export const updateUser = (user) => {
  const formData = new FormData();
  formData.append("name", user.name);
  formData.append("email", user.email);
  formData.append("consent_to_marketing", user.consent_to_marketing);
  return Auth.client.put(`${BACKEND_URL}/${API_VERSION}/users/${user.id}`, formData);
};

// unused
export const getVendorEngagementSettings = () => {
  const decoded = jwtDecode(Auth.getJWT()) || {};
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/users/${decoded.user_id}`).then((res) => {
    return {
      use_special_request: res.data.use_special_request,
      special_request_description: res.data.special_request_description || "",
      use_checkout_message: res.data.use_checkout_message,
      checkout_message: res.data.checkout_message || "",
    };
  });
};

// unused
export const updateVendorEngagementSettings = (vendor) => {
  return Auth.client
    .patch(`${BACKEND_URL}/${API_VERSION}/engagement-settings`, vendor)
    .then((res) => {
      return {
        use_special_request: res.data.use_special_request,
        special_request_description: res.data.special_request_description || "",
        use_checkout_message: res.data.use_checkout_message,
        checkout_message: res.data.checkout_message || "",
      };
    });
};

export const getVendorStripeAccount = (vendorId) => {
  return Auth.client.get(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/stripe-connected-account`,
  );
};

export const redirectStripeConnectOnboarding = (vendorId) => {
  return Auth.client
    .get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/stripe-onboarding`)
    .then((res) => window.location.assign(res.data.redirect));
};

export const getVendorPaypalAccount = (vendorId) => {
  return Auth.client.get(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/paypal-connected-account`,
  );
};

export const redirectPaypalConnectOnboarding = (vendorId) => {
  return Auth.client
    .get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/paypal-onboarding`)
    .then((res) => window.location.assign(res.data.redirect));
};

export const updatePaymentMethods = (vendorId, paymentSettingsObj) => {
  return Auth.client.put(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/payment-methods`,
    paymentSettingsObj,
  );
};

// unused
export const fetchVendorFaq = () => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/faq`);
};

// unused
export const createVendorFaq = (faq) => {
  return Auth.client.put(`${BACKEND_URL}/${API_VERSION}/faq`, faq);
};

// unused
export const deleteVendorFaq = (faqId) => {
  return Auth.client.delete(`${BACKEND_URL}/${API_VERSION}/faq/${faqId}`);
};

// unused
export const reorderVendorFaq = (faqList) => {
  const faqIds = faqList.map((f) => f.faq_id);
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/faq`, faqIds);
};

export const uploadCustomPaynowQr = (vendorId, customPaynowQr) => {
  const data = new FormData();

  // check if paynowQr is string (uploaded before)
  // or if paynowQr is null (to delete qr)
  if (customPaynowQr && typeof customPaynowQr !== "string") {
    data.append("custom_qr_code", customPaynowQr?.file);
  }
  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/custom-paynow-qr`,
    data,
  );
};

export const getApiKeys = (vendorId) => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/api-key`);
};

export const createApiKey = (vendorId, name) => {
  return Auth.client.post(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/api-key`, {
    name: name,
  });
};

export const updateApiKey = (vendorId, apiKey, name) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/api-key`, {
    api_key: apiKey,
    name: name,
  });
};

export const deleteApiKey = (vendorId, apiKey) => {
  return Auth.client.delete(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/api-key`, {
    data: { api_key: apiKey },
  });
};

export const getWebhooks = (vendorId) =>
  Auth.client.get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/webhooks`);

export const createWebhook = (vendorId, data) =>
  Auth.client.post(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/webhooks`, data);

export const updateWebhook = (vendorId, webhookId, data) =>
  Auth.client.put(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/webhooks/${webhookId}`, data);

export const deleteWebhook = (vendorId, webhookId) =>
  Auth.client.delete(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/webhooks/${webhookId}`);
